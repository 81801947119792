import {
  createStyles,
  Image,
  Title,
  Text,
  List,
  Grid,
  useMantineTheme,
  Card,
  Anchor,
  Stack,
  Button,
  Group
} from '@mantine/core';
import ProfilePhoto from '../ProfilePhoto.jpg';
import { useViewportSize } from '@mantine/hooks';
import Break from './Break';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  content: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    [theme.fn.smallerThan('md')]: {
      maxWidth: '90%'
    },
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    borderRadius: '10px',
    //Centered horizontally and vertically
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '75px',
    marginBottom: 'auto',
    padding: theme.spacing.md,
    [theme.fn.smallerThan('md')]: {
      padding: theme.spacing.sm,
      marginTop: '10px'
    }
  },

  title: {
    color: theme.colorScheme,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 30,
    fontWeight: 500,
    [theme.fn.smallerThan('xs')]: {
      fontSize: 28
    }
  },

  image: {
    marginRight: theme.spacing.sm,
    marginLeft: theme.spacing.sm,
    marginTop: '75px',
    [theme.fn.smallerThan('md')]: {
      maxWidth: '95%',
      marginTop: '10px'
    }
  }
}));

function MainContent() {
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const theme = useMantineTheme();
  const content = (
    <Grid.Col md={8} lg={7}>
      <Card className={classes.content}>
        <Title className={classes.title}>Ethan Connelly, MPhys</Title>
        <Break height={0.5} />
        <Text>
          <b>
            About me
            <Break isLine height={0.001} />
          </b>
        </Text>
        <Text>
          Full-stack software engineer specialising in AI research. With over 4 years experience
          building desktop and web applications. Also an investment manager on the eToro platform.
        </Text>
        <Break height={1} />
        <Text>
          <b>
            eToro Investment manager <Break isLine height={0.001} />
          </b>
          <Break height={0.1} />
          <Text size={'sm'}>
            <b>
              Strategy <Break height={0.001} />
            </b>
            I manage a portfolio on the eToro platform. You can see my full track record there and
            copy my investments with the option to opt out with full sovereignty at all times. I aim
            to beat the S&P500 each year. Please, see my profile or my returns page for more
            information.
          </Text>
          <Group position="center">
            <a href="https://etoro.tw/33CLOoH" rel="noreferrer" target="_blank">
              <Button variant="default" radius="md" compact>
                Profile
              </Button>
            </a>
            <a
              rel="noreferrer"
              href="https://factsheets.fundpeak.com/Report/473D3034AE5913E96C29D371E86200945A196630D7EEAC753D427543A78F8C52"
              target="_blank">
              <Button variant="default" radius="md" compact>
                Returns
              </Button>
            </a>
          </Group>
        </Text>
        <Break height={1} />
        <Stack>
          <Text size={'sm'}>
            <b>
              Projects <Break isLine height={0.001} />
            </b>
            <Anchor color="dimmed" href="https://nabrio.com">
              Reva
            </Anchor>{' '}
            - A no-code desktop application for training computer vison models.
            <br />
            <Anchor color="dimmed" href="https://analysedocs.com">
              AnalyseDocs.com
            </Anchor>{' '}
            - A web application for querying documents, powered by chatgpt. Using a RAG based
            system.
          </Text>
          <Text size={'sm'}>
            <b>
              Python <Break isLine height={0.001} />
            </b>
            Built APIs with fastApi, django and flask.
            <br />
            Built pip packages.
            <br />
            Trained DL models.
            <br />
            Packages I've used include PyTorch, Tensorflow, Keras, Pandas, Numpy, Matplotlib,
            FastApi, Django and others.
          </Text>
          <Text size={'sm'}>
            <b>
              TypeScript/Javascript <Break isLine height={0.001} />
            </b>
            Built type safe web and desktop applications with react.js, electron.js and node.js.
            <br />
            Experienced managing global state with redux and RTK-Query.
            <br />
            Built intuitive and clean UIs with Mantine and other popular react component libraries.
            <br />
            Deployed express APIs on AWS and react frontends on Cloudflare.
          </Text>
          <Text size={'sm'}>
            <b>
              Other <Break isLine height={0.001} />
            </b>
            Worked with C++, SQL, Git, Conda, Docker, Linux, AWS, Cloudflare, heroku and Supabase.
          </Text>
        </Stack>

        <Break height={1} />
      </Card>
    </Grid.Col>
  );
  if (width <= 1200) {
    return (
      <>
        <Grid>
          <Grid.Col md={6} lg={3}>
            <Image height={150} src={ProfilePhoto} className={classes.image} />
          </Grid.Col>
          {content}
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid>
        {content}
        <Grid.Col lg={4}>
          <Image height={600} src={ProfilePhoto} className={classes.image} />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default MainContent;
