import {
    useMantineTheme,
  } from '@mantine/core';
interface BreakProps {
  height?: number;
  width?: number;
  isLine?: boolean;
}



function Break({ height, width, isLine }: BreakProps) {
      const theme = useMantineTheme();
  if (isLine) {
    return (
      <>
        <div
          style={{
            lineHeight: height,
            width,
          }}
        >
          <br />
        </div>
        <hr color={theme.colors.blue[0]} />
        <div
          style={{
            lineHeight: height,
            width,
          }}
        >
          <br />
        </div>
      </>
    );
  }
  return (
    <>
      <div
        style={{
          lineHeight: height,
          width,
        }}
      >
        <br />
      </div>
    </>
  );
}

Break.defaultProps = {
  height: 0.5,
  width: 0.5,
  isLine: false,
};

export default Break;
