import { MantineThemeOverride } from "@mantine/core";

const themeDark: MantineThemeOverride = {
  colorScheme: "dark",
  colors: {
    gold: ['#ECD662'],
    green: ['#5D8233'],
    blue: ['#284E78'],
    four: ['#3E215D'],
  },
  };


const themeLight: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    gold: ['#ECD662'],
    green: ['#5D8233'],
    blue: ['#284E78'],
    four: ['#3E215D'],
  },
  };

export {themeDark, themeLight}