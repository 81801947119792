import { Footer, Group, MantineProvider, Stack } from '@mantine/core';
import MainContent from './components/MainContent';
import { ColorSchemeToggle } from './components/ColorSchemeToggle/ColorSchemeToggle';
import { useState } from 'react';
import { themeDark, themeLight } from './themes';
import SocialLinks from './components/SocialLinks';
import EtoroLink from './components/EtoroLink';
import Break from './components/Break';
import { Dots } from './components/Dots';

export default function App() {
  const [theme, setTheme] = useState(themeDark);
  const toggleTheme = (value: any) => {
    const nextColorScheme = value || (theme === themeDark ? themeLight : themeDark);
    setTheme(nextColorScheme);
  };
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <Dots />
      <Stack align="center" spacing="xs">
        <MainContent />
      </Stack>
      <Break height={4} />
      <Footer fixed height={50}>
        <Group position="center">
          {/* <EtoroLink /> */}
          <ColorSchemeToggle toggleTheme={toggleTheme} />
          <SocialLinks />
        </Group>
      </Footer>
    </MantineProvider>
  );
}
