import { createStyles, Button, Group } from '@mantine/core';
import { IconBrandGithub, IconBrandLinkedin, IconBrandTwitter, IconDownload } from '@tabler/icons';
import { Link } from 'react-router-dom';
const useStyles = createStyles((theme) => ({
  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1
    },
    color: theme.colors.gold,
    backgroundColor: theme.colors.blue
  }
}));

function SocialLinks() {
  const { classes } = useStyles();
  return (
    <div
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <Group position="center">
        <Link to="/Ethan_Connelly_CV_Latest.pdf" target="_blank" download>
          <Button
            variant="default"
            leftIcon={<IconDownload size={20} />}
            radius="md"
            compact
            className={classes.control}>
            Download CV
          </Button>
        </Link>
        <a target="_blank" href="https://github.com/EMichaelC" rel="noreferrer">
          <Button variant="default" radius="md" compact className={classes.control}>
            <IconBrandGithub size={20} />
          </Button>
        </a>
        <a target="_blank" href="https://www.linkedin.com/in/ethan-connelly/" rel="noreferrer">
          <Button variant="default" radius="md" compact className={classes.control}>
            <IconBrandLinkedin size={20} />
          </Button>
        </a>
        <a target="_blank" href="https://twitter.com/Ethan_Connelly" rel="noreferrer">
          <Button variant="default" radius="md" compact className={classes.control}>
            <IconBrandTwitter size={20} />
          </Button>
        </a>
      </Group>
    </div>
  );
}

export default SocialLinks;
